import './App.css';
import { RedocStandalone } from 'redoc';

function App() {
  return (
    <div className="App">
      <RedocStandalone specUrl="./cropwise_seeds_backend/api.yaml" />
    </div>
  );
}

export default App;